<template>
  <div class="userinfo-orderList">
    <div class="title pointer flex-center-start" @click="back">
      <i class="el-icon-arrow-left"></i>
      {{ $t('ti-xian') }}
    </div>
    <div class="edit-form">
      <el-form :model="form" ref="form">
        <el-form-item :label="$t('bi-zhong-xie-yi')" prop="coinType" >
          <el-select v-model="form.coinType" @change="changeCoinType">
            <el-option
              v-for="(item, i) in coinTypeList"
              :key="i"
              :value="item.key"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('qu-kuai-lian-wang-luo')"
          prop="network"
          
        >
          <el-select v-model="form.network">
            <el-option
              v-for="(item, i) in linkList"
              :key="i"
              :value="item.key"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
      
        <el-form-item :label="$t('shu-liang')" prop="number" required>
          <el-input
            v-model="form.number"
            clearable
            :placeholder="$t('qing-shu-ru-shu-liang-0')"
          >
            <template slot="suffix">
              {{ form.coinName }}
            </template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <div class="form-info">
            {{ $t('dang-qian-yue') }}
            ${{ userMoney }}
            <!-- <span class="blue">{{form.coinName}}</span> -->
          </div>
        </el-form-item>
        <el-form-item :label="$t('ti-xian-di-zhi')" prop="address" required>
          <el-input
            v-model="form.address"
            :placeholder="$t('qing-shu-ru-ti-xian-di-zhi')"
          ></el-input>
        </el-form-item>
        <el-form-item label="">
          <div class="flex-center-between">
            <div class="flex-center">
              {{ $t('shi-ji-dao-zhang-0') }}
              {{ form.number || '0.00' }} {{ form.coinName }}
            </div>
            <div class="flex-center">
              {{ $t('shou-xu-fei') }}
              0.00%
            </div>
          </div>
        </el-form-item>
        <el-form-item :label="$t('zi-jin-mi-ma')" prop="password" required>
          <el-input
            type="password"
            v-model="form.password"
            :placeholder="$t('qing-shu-ru-ti-xian-di-zhi-0')"
          ></el-input>
        </el-form-item>

        <el-form-item label="" prop="">
          <el-button class="submit-btn" @click="submit">{{ $t('que-ding') }}</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { getWalletInfo, addWithdraw } from '@/api/user'
export default {
  components: {
  },
  data() {
    return {
      form: {
        type: 1,
        coinType: 'btc',
        address: '',
        password: '',
        number: '',
      },
      userMoney: 0,
      eyeUrl: require('@/assets/imgs/icon-eye-black.png'),
      eyeUrl2: require('@/assets/imgs/icon-eye2-black.png'),
      showPassword: false,
      typeList: [
        {
          value: 1,
          label: this.$t('jia-mi-huo-bi')
        },
        {
          value: 2,
          label: this.$t('yin-hang-ka')
        }
      ],
      coinTypeList: [
       
        {
          key: 'usdt',
          name: 'USDT',
          linkList: [
            {
              key: 'trc20',
              name: 'TRC20'
            },
          ]
        }
      ],
      linkList: [],
      qrcodeText: ''
    }
  },
  computed: {
    realNumber() {
      return this.form.number ? this.form.number.toFixed(2) : '0.00'
    }
  },
  mounted() {
    this.linkList = JSON.parse(JSON.stringify(this.coinTypeList[0].linkList))
    this.form.network = this.linkList[0].key
    this.form.coinType = this.coinTypeList[0].key
    this.form.coinName = this.coinTypeList[0].name
    this.init()
  },
  methods: {
    init() {
      getWalletInfo().then((res) => {
        let data = res.data.Items[0]
        this.userMoney = data.Balance
      })
    },
    back() {
      this.$router.go(-1)
    },
    changeCoinType(val) {
      let index = this.coinTypeList.findIndex((v) => {
        return v.key === val
      })
      this.linkList = JSON.parse(
        JSON.stringify(this.coinTypeList[index].linkList)
      )
      this.form.network = this.linkList[0].key
      this.form.coinName = this.coinTypeList[index].name
    },
    submit() {
      this.$refs.form.validate((vali) => {
        if (vali) {
          addWithdraw({
            Price: parseFloat(this.form.number),
            PayPassword: this.form.password,
            Address: this.form.address,
            Currency: 'USDT-TRC20',
          }).then(res => {
            if (res.code == 0) {
              this.$message.success(this.$t('ti-jiao-cheng-gong'))
              this.form.address = ''
              this.form.password = ''
              this.form.number = ''
              this.$refs.form.resetFields()
            } else {
              this.$message.warning(res.msg)
            }
          })
        }
      })
    }
  }
}
</script>